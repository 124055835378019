<template>
  <div id="contact-page">
    <div id="page-header">
      <router-link :to="{name: 'contacts'}" id="page-title"><i class="bi bi-chevron-left"></i> Terug</router-link>
      <ul id="page-actions" class="nav">
        <li v-if="contact" class="nav-item">
          <a href="#" role="button" class="nav-link text-danger" data-toggle="modal" data-target="#removeContactModal" aria-label="Verbinding opheffen">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-dash-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
            </svg>
          </a>
        </li>
        <li class="nav-item">
          <a href="#" role="button" class="nav-link" data-toggle="modal" data-target="#pageHelpModal" aria-label="Help">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-question-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
            </svg>
          </a>
        </li>
      </ul>
    </div>

    <div v-if="loading || error" class="d-flex flex-fill">
      <div class="d-flex flex-column w-100 justify-content-center align-items-center text-center text-black-50">
          <div v-if="loading">
            <loading-indicator></loading-indicator>
            <div>
              <b>Bezig met ophalen van data</b>
              <br>
              Een ogenblik geduld alstublieft
            </div>
          </div>
          <div v-if="error">
            <h3>{{ error }}</h3>
          </div>
      </div>
    </div>

    <div v-if="contact" id="content" class="mb-4">
      <div id="primary-card" class="card mb-4">
        <div class="card-body p-0 d-flex" style="min-height: 138px;">
          <img class="contact-photo" src="https://randomuser.me/api/portraits/lego/1.jpg">
          <h2 class="px-4 py-3">{{ contact.contact_label }}</h2>
        </div>
      </div>

      <div v-for="(request, index) in unreadRequestsByContact(contact)" :key="request.request_id" class="notification-bar mb-2">
        <div class="d-flex align-items-center flex-fill">
          <div class="blob"></div>
          <div>U heeft een nieuw verzoek</div>
        </div>
        <button role="button" class="btn text-reset font-weight-normal" data-toggle="modal" :data-target="`#newRequestModal-${index}`" aria-label="Verzoek">
          Bekijk verzoek <i class="bi bi-chevron-right"></i>
        </button>
      </div>

      <div class="text-secondary font-weight-medium my-4">Laatste verzoeken</div>

      <div class="row row-cols-1">
        <div v-for="(request, index) in contact.requests.slice().reverse()" :key="request.request_id" class="col mb-4">
          <div class="card mnnu-card">
            <div class="card-body d-flex">
              <div class="flex-fill">
                <h5 class="card-title">{{ request.request_label }}</h5>
                <p class="card-text pre-formatted">{{ request.description }}</p>
                <small class="text-muted">{{ request.created }}</small>
              </div>
              <span v-if="request.status === 0" class="text-success font-weight-medium"><i class="bi bi-check-lg"></i> Geaccepteerd</span>
              <span v-else-if="request.status === 1" class="text-danger font-weight-medium"><i class="bi bi-x-lg"></i> Geweigerd</span>
              <span v-else-if="request.status === 3" class="text-warning font-weight-medium">Onbeantwoord</span>
            </div>
          </div>
          <div v-if="request.status === 0" class="request-details">
            <div class="collapse" :id="`requestCollapse${index}`">
              <div class="d-flex">
                <div class="flex-fill">
                  <p>De volgende gegevens heeft u gedeeld met deze behandelaar:</p>
                  <ul>
                    <li v-for="attribute in request.attributes" :key="attribute">{{ attribute }}</li>
                  </ul>
                </div>
                <div>
                  <button role="button" class="btn btn-danger font-weight-normal" data-toggle="modal" data-target="stopSharingModal" aria-label="Stop delen">
                    <i class="bi bi-x-octagon"></i> Delen stoppen
                  </button>
                </div>
              </div>
            </div>
            <button class="btn text-secondary p-0 font-weight-normal" type="button" data-toggle="collapse" :data-target="`#requestCollapse${index}`" aria-expanded="false" :aria-controls="`requestCollapse${index}`">
              Bekijk gegevens en acties
            </button>
          </div>
        </div>
      </div>
    </div>

    <modal id="pageHelpModal" title="Behandelaar">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ullamcorper aliquet nulla a facilisis. Vivamus aliquam ex ut justo suscipit, eu vestibulum justo dictum. Nulla varius, tortor nec tempor pellentesque, erat velit tincidunt libero, eu fringilla erat massa volutpat turpis. Aliquam et nibh massa. Vivamus nulla ipsum, ornare vitae felis ut, fringilla venenatis risus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. In sed ornare nisl. Mauris nec nunc quis purus varius aliquet. Integer a pellentesque ligula. Etiam et imperdiet urna.</p>
    </modal>

    <modal v-for="(request, index) in unreadRequestsByContact(contact)" :key="request.request_id" :id="`newRequestModal-${index}`" :header="false" :footer="false">
      <div class="mb-4 text-center">
        <img class="mb-2" src="https://randomuser.me/api/portraits/lego/1.jpg" style="max-height: 90px">
        <h2>{{ request.request_label }}</h2>
      </div>
      <div>
        <p><b>Wil graag toestemming voor:</b></p>
        <ul>
          <li v-for="attribute in request.attributes" :key="attribute">{{ attribute }}</li>
        </ul>
        <p><b>Reden:</b></p>
        <p class="pre-formatted">{{ request.description }}</p>
      </div>
      <div class="mt-5">
        <button class="btn btn-success btn-block mb-3" @click="processRequest(request, true)">Toestaan</button>
        <button class="btn btn-danger btn-block mb-3" @click="processRequest(request, false)">Weigeren</button>
        <button class="btn btn-outline-primary btn-block" data-dismiss="modal">Beslissing uitstellen</button>
      </div>
    </modal>

    <modal id="stopSharingModal" title="Delen stoppen?">
      <div>Deze actie kan niet ongedaan worden gemaakt.</div>
      <template v-slot:footer>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuleren</button>
        <button type="button" class="btn btn-danger">Stop delen</button>
      </template>
    </modal>

    <modal id="removeContactModal" title="Verbinding met behandelaar opheffen?">
      <div>Deze actie kan niet ongedaan worden gemaakt.</div>
      <template v-slot:footer>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Annuleren</button>
        <button type="button" class="btn btn-danger" @click="deleteContact()">Verbinding opheffen</button>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Modal from '@/components/ui/Modal'
import LoadingIndicator from '@/components/ui/LoadingIndicator'

export default {
  name: 'Contact',
  components: { Modal, LoadingIndicator },
  props: {
    contactId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      loading: false,
      contact: null,
      error: null
    }
  },
  created () {
    this.fetchContact()
  },
  watch: {
    $route: 'fetchContact'
  },
  computed: {
    ...mapGetters([
      'contactById',
      'unreadRequestsByContact'
    ])
  },
  methods: {
    ...mapActions([
      'changeRequestStatus',
      'removeContact'
    ]),
    fetchContact () {
      this.error = this.contact = null
      this.loading = true
      const contactId = parseInt(this.contactId)

      const data = this.contactById(contactId)
      this.loading = false

      if (Number.isNaN(contactId) || data === null) {
        this.error = 'Behandelaar bestaat niet'
      } else {
        this.contact = data
      }
    },
    processRequest (request, accepted) {
      switch (accepted) {
        case true:
          this.changeRequestStatus({
            contactId: this.contact.contact_id,
            requestId: request.request_id,
            status: 0
          })
          break
        case false:
          this.changeRequestStatus({
            contactId: this.contact.contact_id,
            requestId: request.request_id,
            status: 1
          })
          break
      }

      $('[id^=newRequestModal-]').modal('hide')
    },
    deleteContact () {
      this.removeContact(this.contact)
      $('#removeContactModal').modal('hide')
      this.$router.push({ name: 'contacts' })
    }
  }
}
</script>
